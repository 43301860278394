import "photoswipe/dist/photoswipe.css";
import "photoswipe/dist/default-skin/default-skin.css";

import $ from "jquery";

import PhotoSwipe from "photoswipe";
import PhotoSwipeUI_Default from "photoswipe/dist/photoswipe-ui-default";

function initPhotoswipe(galleryElement) {
  if (!galleryElement) return;

  const $images = $(galleryElement).find("img");

  const items = $images.get().map((el) => {
    const $img = $(el);

    return {
      title: $img.attr("alt"),
      src: $img.attr("data-full-src"),
      w: $img.attr("data-full-width"),
      h: $img.attr("data-full-height"),
      msrc: $img.attr("data-src"),
      el,
    };
  });

  const options = {
    getThumbBoundsFn(id) {
      const pageYScroll = $(window).scrollTop();
      const item = items[id];
      const { top, left, width, height } = item.el.getBoundingClientRect();

      const itemRatio = item.w / item.h;
      const elRatio = width / height;

      const isLandscape = elRatio >= itemRatio;
      console.log(isLandscape);
      const offsetX = isLandscape
        ? 0
        : -(height * (item.w / item.h - width / height)) / 2;
      const offsetY = isLandscape
        ? -(width * (item.h / item.w - height / width)) / 2
        : 0;

      return {
        x: left + offsetX,
        y: top + pageYScroll + offsetY,
        w: width + 2 * Math.abs(offsetX),
      };
    },
    bgOpacity: 1,
    history: false,
  };

  $images.on("click", (evt) => {
    evt.preventDefault();

    new PhotoSwipe($(".pswp").get(0), PhotoSwipeUI_Default, items, {
      ...options,
      index: $images.index(evt.target),
    }).init();
  });
}

$(".photoswipe-gallery").each((_, el) => initPhotoswipe(el));
